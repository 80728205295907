export default {
    computed: {
        modules() {
            return this.$store.state['price-leadtime'].modules;
        },
        modulesNames() {
            return Object.keys(this.modules).reduce((output, key) => {
                output[this.capitalizeKeys(key)] = key;

                return output;
            }, {});
        },
        disabledModules() {
            return Object.keys(this.modules).filter(key => this.modules[key] === 'disabled');
        },
    },
    methods: {
        capitalizeKeys(key) {
            return key
                .replace('-module', '')
                .split('-')
                .map((item, index) => index === 0 ? item : this.capitalize(item))
                .join('');
        },
        getIsModuleDisabled(moduleName) {
            return this.disabledModules.includes(moduleName);
        },
    },
}