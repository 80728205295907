<template>
    <button type="button" class="btn mr-2" :disabled="!isCreationAllowed" @click="$emit('click')">
        Save as new template
    </button>
</template>

<script>
import { validateEmailPlt } from '~/data/regex';

export default {
    computed: {
        isCreationAllowed() {
            return this.isAddressValid;
        },
        addressData() {
            return this.$store.getters['price-leadtime/address'];
        },
        isAddressValid() {
            const data = Object.entries(this.addressData);

            const result = data.filter(item => {
                let valid = true;

                if (item[1].email && !validateEmailPlt(item[1].email)) {
                    valid = false;
                }

                return valid;
            });

            return data.length === result.length;
        },
    },
}
</script>