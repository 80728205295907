<template>
    <div class="mt-4">
        <quote-module :name="modulesNames.customClearance">
            <dropdown-block title="Customs Invoice" :default-show="true" class="mb-4">
                <card body-class="flex flex-wrap overflow-visible">
                    <modal-field
                        required
                        :label="labels.invoiceNumber"
                        class="w-1/2 mt-2 pr-2"
                        :class="{ empty: getIsEmptyField(data.invoicenumber) }"
                    >
                        <input
                            v-model="data.invoicenumber"
                            :disabled="getIsModuleDisabled(modulesNames.customClearance)"
                            type="text"
                        >
                    </modal-field>
    
                    <modal-field
                        required
                        :label="labels.invoiceDate"
                        class="w-1/2 mt-2 pl-2"
                        :class="getIsEmptyField(data.invoicedate)"
                    >
                        <date-picker
                            v-model="data.invoicedate"
                            :disable-past="false"
                            :disabled="getIsModuleDisabled(modulesNames.customClearance)"
                        />
                    </modal-field>
                
                    <modal-field
                        required
                        :label="labels.exportReason"
                        class="w-1/2 mt-2 pr-2"
                        :class="{ empty: getIsEmptyField(data.exportreason) }"
                    >
                        <multiselect
                            v-model="data.exportreason"
                            :options="options.reason"
                            :disabled="getIsModuleDisabled(modulesNames.customClearance)"
                            label="label"
                            track-by="value"
                        />
                    </modal-field>
    
                    <modal-field :label="labels.declarationStatement" class="w-1/2 mt-2 pl-2">
                        <input
                            v-model="data.declarationstatement"
                            :disabled="getIsModuleDisabled(modulesNames.customClearance)"
                            type="text"
                        >
                    </modal-field>
    
                    <modal-field :label="labels.shipmentType" class="w-1/2 mt-2 pr-2">
                        <multiselect
                            v-model="data.shipmenttype"
                            :options="options.shipmentType"
                            :disabled="getIsModuleDisabled(modulesNames.customClearance)"
                            label="label"
                            track-by="value"
                            preselect-first
                        />
                    </modal-field>
    
                    <modal-field :label="labels.otherComments" class="w-1/2 mt-2 pl-2">
                        <input
                            v-model="data.othercomments"
                            :disabled="getIsModuleDisabled(modulesNames.customClearance)"
                            type="text"
                        >
                    </modal-field>
    
                    <modal-field
                        :label="labels.freightcharges"
                        class="w-1/2 mt-2 pr-2"
                        :class="{ empty: getIsEmptyField(data.freightcharges) }"
                    >
                        <input
                            v-model="data.freightcharges"
                            :disabled="getIsModuleDisabled(modulesNames.customClearance)"
                            type="text"
                        >
                    </modal-field>
                </card>
            </dropdown-block>
        </quote-module>

        <quote-module :name="modulesNames.customValue">
            <dropdown-block title="Goods value" :default-show="true" class="mb-4">
                <card class="flex" body-class="overflow-visible">
                    <modal-field
                        required
                        :label="labels.valueOfGoodsAmount"
                        :class="[widthClass, { empty: getIsEmptyField(data.valueOfGoodsAmount) }]"
                    >
                        <input
                            v-model="data.valueOfGoodsAmount"
                            :disabled="getIsModuleDisabled(modulesNames.customData)"
                            type="number"
                        >
                    </modal-field>
                    <modal-field
                        required
                        :label="labels.valueOfGoodsCurrency"
                        :class="[widthClass, { empty: getIsEmptyField(data.valueOfGoodsCurrency) }]"
                    >
                        <multiselect
                            v-model="data.valueOfGoodsCurrency"
                            :options="options.currency"
                            :disabled="getIsModuleDisabled(modulesNames.customData)"
                        />
                    </modal-field>
                    <modal-field :label="labels.valueOfGoodsDutiable" :class="widthClass">
                        <v-checkbox
                            v-model="data.valueOfGoodsDutiable"
                            :disabled="
                                getIsModuleDisabled(modulesNames.customData)
                                || getIsLockedField('carrier_service')
                                || isUnifaunTemplateWithService
                            "
                        />
                    </modal-field>
                </card>
            </dropdown-block>
        </quote-module>

        <quote-module :name="modulesNames.customData">
            <dropdown-block title="Customs Data" :default-show="true">
                <card body-class="flex flex__column overflow-visible">
                    <div
                        v-for="(good, index) in data.goods"
                        :key="`customs-modal-good_${index}`"
                        :class="{ 'mt-4': index > 0 }"
                        class="customs-modal-good pl-4"
                        :data-index="`${index + 1}.`"
                    >
                        <div class="flex w-full">
                            <modal-field
                                required
                                :label="labels.goodsType"
                                :class="[widthClass, { empty: getIsEmptyField(good.goodsType) }]"
                            >
                                <input
                                    v-model="good.goodsType"
                                    :disabled="getIsModuleDisabled(modulesNames.customData)"
                                    type="text"
                                >
                            </modal-field>
                            <modal-field
                                required
                                :label="labels.goodsDescription"
                                :class="[widthClass, { empty: getIsEmptyField(good.goodsDescription) }]"
                            >
                                <input
                                    v-model="good.goodsDescription"
                                    :disabled="getIsModuleDisabled(modulesNames.customData)"
                                    type="text"
                                >
                            </modal-field>
                            <modal-field
                                required
                                :label="labels.netWeight"
                                :class="[widthClass, { empty: getIsEmptyField(good.netWeight) }]"
                            >
                                <input
                                    v-model="good.netWeight"
                                    :disabled="getIsModuleDisabled(modulesNames.customData)"
                                    type="number"
                                >
                            </modal-field>
                        </div>
                        <div class="flex">
                            <modal-field
                                required
                                :label="labels.statisticalNo"
                                :class="[widthClass, customsDataClass, { empty: getIsEmptyField(good.statisticalNo) }]"
                            >
                                <input
                                    v-model="good.statisticalNo"
                                    :disabled="getIsModuleDisabled(modulesNames.customData)"
                                    type="number"
                                >
                            </modal-field>
                            <modal-field
                                required
                                :label="labels.countryOfOrigin"
                                :class="[widthClass, customsDataClass, { empty: getIsEmptyField(good.countryOfOrigin) }]"
                            >
                                <multiselect
                                    v-model="good.countryOfOrigin"
                                    :options="countriesOptions"
                                    :disabled="getIsModuleDisabled(modulesNames.customData)"
                                    track-by="id"
                                >
                                    <div slot="singleLabel" slot-scope="{ option }">
                                        {{ getCountryLabel(option) }}
                                    </div>
                                    <div slot="option" slot-scope="{ option }">
                                        {{ getCountryLabel(option) }}
                                    </div>
                                </multiselect>
                            </modal-field>
                            <modal-field :label="labels.customsDescription" :class="[widthClass, customsDataClass]">
                                <input
                                    v-model="good.customsDescription"
                                    :disabled="getIsModuleDisabled(modulesNames.customData)"
                                    type="text"
                                >
                            </modal-field>
                        </div>
                        <div class="flex">
                            <modal-field :label="labels.invoiceDate" :class="[widthClass, customsDataClass]">
                                <date-picker
                                    v-model="good.invoiceDate"
                                    :disable-past="false"
                                    :disabled="getIsModuleDisabled(modulesNames.customData)"
                                />
                            </modal-field>
                            <modal-field :label="labels.invoiceNumber" :class="[widthClass, customsDataClass]">
                                <input
                                    v-model="good.invoiceNo"
                                    :disabled="getIsModuleDisabled(modulesNames.customData)"
                                    type="text"
                                >
                            </modal-field>
                            <modal-field :label="labels.documentType" :class="[widthClass, customsDataClass]">
                                <multiselect
                                    v-model="good.documentType"
                                    :options="options.documentType"
                                    :disabled="getIsModuleDisabled(modulesNames.customData)"
                                    label="label"
                                    track-by="value"
                                />
                            </modal-field>
                        </div>
                        <div class="flex">
                            <modal-field
                                required
                                :label="labels.numberOfGoodsItems"
                                :class="[widthClass, customsDataClass, { empty: getIsEmptyField(good.numberOfGoodsItems) }]"
                            >
                                <input
                                    v-model="good.numberOfGoodsItems"
                                    :disabled="getIsModuleDisabled(modulesNames.customData)"
                                    type="number"
                                >
                            </modal-field>
                            <modal-field
                                required
                                :label="labels.unitPrice"
                                :class="[widthClass, customsDataClass, { empty: getIsEmptyField(good.unitPrice) }]"
                            >
                                <input
                                    v-model="good.unitPrice"
                                    :disabled="getIsModuleDisabled(modulesNames.customData)"
                                    type="number"
                                >
                            </modal-field>
                            <modal-field
                                required
                                :label="labels.unitOfMeasureCode"
                                :class="[widthClass, customsDataClass, { empty: getIsEmptyField(good.unitOfMeasureCode) }]"
                            >
                                <multiselect
                                    v-model="good.unitOfMeasureCode"
                                    :disabled="getIsModuleDisabled(modulesNames.customData)"
                                    :options="options.unitOfMeasureCode"
                                />
                            </modal-field>
                        </div>
                        <div v-if="data.goods.length > 1" class="flex mt-2">
                            <button
                                :disabled="getIsModuleDisabled(modulesNames.customData)"
                                class="btn-transparent"
                                @click="removeGood(index)"
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                    <div class="mt-4 w-full flex justify-center">
                        <button
                            :disabled="getIsModuleDisabled(modulesNames.customData)"
                            class="custom-add-item-btn btn flex align-center"
                            @click="addGood"
                        >
                            <svg-importer icon-name="icons/plus" key="customs-modal-add" width="18"/>
                            Add
                        </button>
                    </div>
                </card>
            </dropdown-block>
        </quote-module>
    </div>
</template>
<script>
import ModalField from "~/components/ModalField";
import Multiselect from 'vue-multiselect';
import VCheckbox from '~/components/Checkbox';
import DatePicker from '~/components/DatePicker';
import DropdownBlock from '~/components/DropdownBlock.vue';
import QuoteModule from '~/components/PriceLeadTime/QuoteModule.vue';

import customsOptions from './customsOptions.json';

import lockedMixin from '~/pages/transport/price-leadtime/locked.mixin.js';
import quoteModulesMixin from '~/mixins/quoteModules.mixin.js';

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    mixins: [lockedMixin, quoteModulesMixin],
    model: {
        prop: 'value',
        event: 'update:value',
    },
    components: {
        ModalField,
        Multiselect,
        VCheckbox,
        DatePicker,
        DropdownBlock,
        QuoteModule,
    },
    data() {
        return {
            widthClass: 'w-1/3 pr-2',
            customsDataClass: 'mt-2',
            labels: {
                invoiceDate: 'Invoice date',
                exportReason: 'Export reason',
                declarationStatement: 'Declaration statement',
                freightcharges: 'Freight charges',
                shipmentType: 'Shipment type',
                otherComments: 'Other comments',
                valueOfGoodsAmount: 'Amount',
                valueOfGoodsCurrency: 'Currency',
                valueOfGoodsDutiable: 'Dutiable',
                goodsType: 'Goods type (article number)',
                goodsDescription: 'Goods description',
                netWeight: 'Net weight (kg)',
                statisticalNo: 'Statistical number',
                countryOfOrigin: 'Country of origin',
                customsDescription: 'Description',
                invoiceNumber: 'Invoice number',
                documentType: 'Document type',
                unitOfMeasureCode: 'Unit of measure code',
                numberOfGoodsItems: 'Number of goods items',
                unitPrice: 'Unit price',
            },
            options: customsOptions,
        };
    },
    computed: {
        data: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('update:value', value);
            }
        },
        countriesOptions() {
            return this.$store.getters['countries/list'];
        },
        currentTemplate() {
            return this.$store.getters['price-leadtime/currentTemplate'];
        },
        isUnifaunTemplateWithService() {
            return Boolean(this.currentTemplate?.unifaun_pk && this.currentTemplate?.carrier_service_id);
        },
    },
    methods: {
        addGood() {
            this.data.goods.push({});
        },
        removeGood(index) {
            this.data.goods.splice(index, 1);
        },
        getCountryLabel(country) {
            return `${country.code} - ${country.name}`;
        },
    },
}
</script>
<style lang="scss" scoped>
.customs-modal-good {
    position: relative;

    &::before {
        content: attr(data-index);
        position: absolute;
        left: 0;
        top: 0.1rem;
    }
}
.custom-add-item-btn {
    svg {
        margin-top: -2px;
    }
}
</style>