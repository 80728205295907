<template>
    <modal :show="show" size="sm" @hide="hide">
        <h2 slot="header">
            Warning!
        </h2>

        <template slot="body">
            On selecting new template all filled-in data will be lost.
            <br />
            Would you like to continue?
        </template>

        <div slot="footer" class="flex justify-center w-full">
            <button class="btn btn-primary mr-4" @click="$emit('continue')">
                Continue
            </button>
            <button class="btn btn-transparent" @click="hide">
                Cancel
            </button>
        </div>
    </modal>
</template>

<script>

export default {
    props: {
        show: Boolean,
    },
    model: {
        prop: 'show',
        event: 'show:update',
    },
    computed: {
        showComputed: {
            get() {
                return this.show;
            },
            set(val) {
                this.$emit('show:update', val)
            },
        },
    },
    methods: {
        hide() {
            this.showComputed = false;
        },
    },
}
</script>