<template>
  <modal :show="show" size="sm" class="dutiable-services-modal" :closeable="false" hide-header>
    <div slot="body" class="modal-body pt-4">
        <p class="f-s-16 mb-2">The carrier service you chose can not be used for this shipment. Please, select another one.</p>
        <multiselect
            v-model="value"
            :options="options"
            label="name"
            track-by="id"
        />
    </div>
    <div slot="footer" class="w-full flex justify-center">
        <button class="btn" :disabled="!value" @click="$emit('select', value)">
            Select
        </button>
    </div>
  </modal>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'DutiableServicesModal',
    components: {
        Multiselect,
    },
    props: {
        show: false,
        options: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            value: null,
        };
    }
}
</script>

<style lang="scss">
.dutiable-services-modal {
    .modal__body {
        min-height: 275px;
    }
    .multiselect__content-wrapper {
        max-height: 160px !important;
    }
}
</style>